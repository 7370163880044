<template>
  <PackageDetailsTemplate
    :headerProps="headerProps"
    :socialProps="socialProps"
    :accomodation="accomodation"
    :socialList="socialList"
    :pages="pages"
  />
</template>

<script>
import PackageDetailsTemplate from "@/components/templates/PackageDetails/PackageDetails";
import {
  headerProps,
  socialProps,
  accomodation,
} from "@/components/constants.js";
import heroImg from "@/assets/images/sample-hero.jpg";
export default {
  components: {
    PackageDetailsTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      accomodation: accomodation.list[2],
      socialList: accomodation.social,
      pages: [{ image: heroImg }],
    };
  },
};
</script>

<style scoped></style>
