<template>
    <HeaderSection v-bind="headerProps" />
    <SocialBox v-bind="socialProps" />
    <section class="hero-media">
		<Carousel :items="pages" />
	</section>
    <div class="content-wrap clearfix">
		<div class="entry entry-page">
			<div class="entry-content">
				<div class="grid-container">
					<div class="grid-column no-animation">
						<AccomodationInfo :accomodation="accomodation" :socialList="socialList" />
					</div>
					<div class="grid-column">
						<p class="pb-6" v-for="(info, index) in accomodation.about" :key="index">{{ info }}</p>
					</div>
				</div>
                <div class="gallery-masonry">
                    <AccomodationImage v-bind="image" v-for="(image, index) in accomodation.images" :key="index" />
				</div>
			</div>
		</div>
		<CopyRight v-bind="footerProps" />
	</div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import AccomodationInfo from '../../organisms/AccomodationInfo/AccomodationInfo.vue';
import AccomodationImage from '../../molecules/AccomodationImage/AccomodationImage.vue';
import Button from '../../atoms/Button/Button.vue';
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import Carousel from '../../organisms/Carousel/Carousel.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';

/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection,
        AccomodationInfo,
        AccomodationImage,
        Button,
        SocialBox,
        Carousel,
        CopyRight
    },
    props: {
        headerProps: {
            type: Object,
            default: () => null
        },
        socialProps: {
            type: Object,
            default: () => null
        },
        accomodation: {
            type: Object,
            default: () => {},
        },
        socialList: {
            type: Array,
            default: () => []
        },
        pages: {
            type: Array,
            default: () => []
        }
    },
    beforeCreate() {
        this.$nextTick().then(() => {
            document.body.classList.add('single-accomodation')
            document.body.classList.add('single')
            document.body.classList.remove('full-content')
        })
    },
    data() {
        return {
            footerProps
        }
    }
}
</script>
<style>
    @media (min-width: 1200px) {
        .single-accomodation .fullpage #site-menu { background-color: #fff; }
    }
</style>