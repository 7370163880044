<template>
   <figure>
        <a :href-lang="image" data-fancybox="gallery">
            <img :src="image" width="310" height="465" alt="">
        </a>
        <figcaption>{{ title }}</figcaption>
    </figure>
</template>

<script>

export default {
    props: {
        image: {
            type: String,
            default: () => '',
        },
        height: {
            type: Number,
            default: () => null,
        },
        width: {
            type: Number,
            default: () => 0,
        },
        title: {
            type: String,
            default: () => ''
        }
    }
};
</script>
