<template>
    <header class="entry-header">
        <h2 class="entry-title"><a href="single-accomodation.html">{{ type }}</a></h2>
        <ul class="mb-6">
            <li>Max. {{ maxPerson }} person</li>
            <li v-if="isFreeCancellation">Free cancellation</li>
            <li>{{ benefit }}</li>
        </ul>
        <a href="single-accomodation.html" class="accomodation-price"><span class="currency">$</span><span class="price">{{ price }}</span><span class="price-per"> / {{ priceType }}</span></a>
        <slot />
    </header>
</template>

<script>

export default {
    props: {
        type: {
            type: String,
            default: () => '',
        },
        maxPerson: {
            type: Number,
            default: () => null,
        },
        isFreeCancellation: {
            type: Boolean,
            default: () => false,
        },
        benefit: {
            type: String,
            default: () => '',
        },
        price: {
            type: Number,
            default: () => 0,
        },
        priceType: {
            type: String,
            default: () => '',
        },
    }
};
</script>
