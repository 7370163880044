<template>
    <article class="entry-accomodation">
        <AccomodationText v-bind="accomodation">
            <form class="form-booking-button" action="javascript:void(0)">
                <Button type="submit" class="button button-color submit-btn" size="size" variant="abc" @click="$router.push('/booking_result')">Book Now</Button>
                <div class="share-widget-container">
                    <a href="#share-widget" class="button" style="padding: 1.2em 2.7em;"><span class="mdi mdi-share-variant"></span></a>
                    <div id="share-widget">
                        <a :href="social.link" target="_blank" :title="'Share via ' + social.text" v-for="(social, index) in socialList" :key="index">
                            <span class="mdi" :class="social.icon"></span>
                            <span class="screen-reader-text">{{ social.text }}</span>
                        </a>
                    </div>
                </div>
            </form>
        </AccomodationText>
    </article>
</template>

<script>
import AccomodationText from "../../molecules/AccomodationText/AccomodationText.vue";
import Button from '../../atoms/Button/Button.vue';

export default {
  components: {
    AccomodationText,
    Button
  },
  props: {
    accomodation: {
      type: Object,
      default: () => {},
    },
    socialList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
